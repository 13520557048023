/* global angular:readonly, CookiesEuBanner:readonly, dataLayer:readonly, firebase:readonly, gtag:readonly */

"use strict";

((angular, CookiesEuBanner, dataLayer, gtag) => {
	new CookiesEuBanner(() => {
		gtag("consent", "update", {
			ad_storage: "granted",
			analytics_storage: "granted"
		});
	});

	const defaultConfig = {
		"default_hostname": "z0p.org",
		"privacy_policy": "https://static.z0p.org/web/privacy-policy.html"
	};

	Object.freeze(defaultConfig);

	let defaultHostname = defaultConfig.default_hostname;
	let privacy_policy = defaultConfig.privacy_policy;

	const firebaseConfig = {
		apiKey: "AIzaSyBL6bZ6wy64IEs2J_P1Lm3lgUnuZnQSD8k",
		appId: "1:305321770973:web:b36b85d28d087f6e0e0034",
		authDomain: "firebase.z0p.org",
		databaseURL: "https://z0p-website-default-rtdb.firebaseio.com",
		measurementId: "G-HDE2PVQ0BF",
		messagingSenderId: "305321770973",
		projectId: "z0p-website",
		storageBucket: "z0p-website.appspot.com"
	};

	Object.freeze(firebaseConfig);

	const firebaseApp = firebase.initializeApp(firebaseConfig, {
		automaticDataCollectionEnabled: true,
		name: "[DEFAULT]"
	});

	const firebaseAnalytics = firebaseApp.analytics();

	function initializeFundingChoices(property, firebaseAnalytics) {
		property.googlefc = property.googlefc || {};
		property.googlefc.ccpa = property.googlefc.ccpa || {};
		property.googlefc.callbackQueue = property.googlefc.callbackQueue || [];

		property.googlefc.callbackQueue.push({
			AD_BLOCK_DATA_READY: () => {
				const adBlockerStatus = property.googlefc.getAdBlockerStatus();
				const adBlockerStatusEnum = Object.freeze(property.googlefc.AdBlockerStatusEnum);

				firebaseAnalytics.logEvent(Object.keys(adBlockerStatusEnum).find(status => adBlockerStatusEnum[status] === adBlockerStatus), {
					event_category: "ad_blocker_status",
					non_interaction: true
				});

				const allowAdsStatus = property.googlefc.getAllowAdsStatus();
				const allowAdsStatusEnum = Object.freeze(property.googlefc.AllowAdsStatusEnum);

				firebaseAnalytics.logEvent(Object.keys(allowAdsStatusEnum).find(status => allowAdsStatusEnum[status] === allowAdsStatus), {
					event_category: "allow_ads_status",
					non_interaction: true
				});
			}
		});

		property.googlefc.callbackQueue.push({
			INITIAL_CCPA_DATA_READY: () => {
				const initialCcpaStatus = property.googlefc.ccpa.getInitialCcpaStatus();
				const initialCcpaStatusEnum = Object.freeze(property.googlefc.ccpa.InitialCcpaStatusEnum);

				firebaseAnalytics.logEvent(Object.keys(initialCcpaStatusEnum).find(status => initialCcpaStatusEnum[status] === initialCcpaStatus), {
					event_category: "initial_ccpa_status",
					non_interaction: true
				});
			}
		});
	}

	initializeFundingChoices(window, firebaseAnalytics);

	firebaseApp.performance();

	const firebaseRemoteConfig = firebaseApp.remoteConfig();

	firebaseRemoteConfig.defaultConfig = defaultConfig;

	firebaseRemoteConfig.fetchAndActivate().then(() => {
		defaultHostname = firebaseRemoteConfig.getString("default_hostname");

		privacy_policy = firebaseRemoteConfig.getString("privacy_policy");
	});

	const app = angular.module("z0p-website", ["ngAnimate", "ngSanitize", "angular-loading-bar", "vcRecaptcha"]);

	app.config(($compileProvider, $locationProvider) => {
		$compileProvider.debugInfoEnabled(false);
		$locationProvider.html5Mode(true);
	});

	app.controller("main", ($http, $location, $scope, $window) => {
		const currentHostname = $location.host();

		if (currentHostname != "localhost" && currentHostname != defaultHostname) {
			const promises = [];
			if ("serviceWorker" in navigator) {
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					for (const registration of registrations) {
						promises.push(registration.unregister());
					}
				});
			}
			Promise.all(promises).then(() => {
				location.href = $location.protocol() + "://" + defaultHostname + $location.url();
			});
		}

		function blobstoreSubmit() {
			$scope.errorMessage = null;
			$scope.successMessage = null;
			$http.get("/z0p/api/blobstore").then((response) => {
				const data = response.data;
				const formData = new FormData();
				const fileElement = angular.element("#file")[0];
				const file = fileElement.files[0];
				formData.append("file", file);
				$http.post(data.upload_url, formData, {
					headers: {
						"Content-Type": undefined
					},
					transformRequest: angular.identity
				}).then((response) => {
					const data = response.data;
					$scope.successMessage = data.url;
				}, (response) => {
					const data = response.data;
					$scope.errorMessage = data.errorMessage;
				});
			});
		}

		function scrollToTop() {
			$window.scrollTo(0, 0);
		}

		function urlShortenerSubmit() {
			$scope.errorMessage = null;
			$scope.successMessage = null;
			$http.post("/z0p/api/url", $scope.urlShortenerModel).then((response) => {
				const data = response.data;
				$scope.successMessage = "https://z0p.org/" + data.shortURL;
			}, (response) => {
				const data = response.data;
				$scope.errorMessage = data.errorMessage;
			});
		}

		$scope.privacy_policy = privacy_policy;

		$scope.blobstoreSubmit = blobstoreSubmit;
		$scope.scrollToTop = scrollToTop;
		$scope.urlShortenerSubmit = urlShortenerSubmit;

		$scope.recaptchaPublicKey = "6Lf9ck0UAAAAAG-N7eBf-yCZLWUFRUp49T0SShMF";

		$http.get("/z0p/api/user").then((response) => {
			const data = response.data;
			$scope.user = data;
			if (data.user) {
				if (data.user.user_id) {
					firebaseAnalytics.setUserId(data.user.user_id, {
						global: true
					});
				}
			}
		});

		if ((currentHostname == "localhost" || currentHostname == defaultHostname) && "serviceWorker" in navigator) {
			const serviceWorker = "/z0p/sw/index.min.js";

			navigator.serviceWorker.register(serviceWorker, { scope: "/z0p/" });
		}
	});
})(angular, CookiesEuBanner, dataLayer, gtag);
